import React, { useEffect, useState } from "react";
import { stackStyled } from "../../theme";
import useFindYourSafe from "../FindYourSafe/useFindYourSafe";
import TitleAndSubtitle from "./TitleAndSubtitle";

const ProductFinderContainer = () => {
  const { toggleOverlay } = useFindYourSafe();
  const [newUrl, setNewUrl] = useState("");
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      return;
    } else {
      const protocol = window.location.protocol;
      const hostName = window.location.hostname;
      const port = window.location.port;
      let urlTemp = "";
      if (port != "") {
        urlTemp = protocol + "//" + hostName + ":" + port + "/";
      } else {
        urlTemp = protocol + "//" + hostName + "/";
      }
      localStorage.setItem("fromProductFinder", "yes");
      setNewUrl(urlTemp);
      setTimer(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(newUrl);
    }, 50);
  }, [timer]);

  const MainContainer = stackStyled("div", () => ({
    textAlign: "center",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));

  return (
    <MainContainer>
        <div>
      <TitleAndSubtitle
        title={"Find my Stack-On"}
        subTitle={"Find the safe that best adapts to your needs!"}
        colortitle={"#FFFFFF"}
        colorSubTitle={"#C0C994"}
      />
      </div>
    </MainContainer>
  );
};

export default ProductFinderContainer;
