import React, { useEffect } from "react";
import { PageProps } from "gatsby";
import { LayoutProvider, Wrapper } from "../components/layout";
import ProductFinderContainer from "../atoms/Containers/ProductFinder";

export const ProductFinder: React.FC<PageProps> = (props: PageProps) => {

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <div style={{ backgroundColor: "#2A3C24" }}>
        <ProductFinderContainer />
      </div>
    </LayoutProvider>
  );
};

export default ProductFinder;
